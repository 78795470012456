'use strict';

/*global Modernizr */
import store from './_store';
import helpers from './_helpers';
import ResponsiveDebug from './class/ResponsiveDebug';
import Collapsible from './class/Collapsible';
import ScrollAnchor from './class/ScrollAnchor';
import ShowPassword from './class/ShowPassword';
import ClearInput from './class/ClearInput';
import DetectBrowser from './class/DetectBrowser';
import Cookies from './class/Cookies';
import BannerMessages from './class/BannerMessages';
import ValidForm from './class/ValidForm';
// import AnimatedLabelField from './class/AnimatedLabelField';
//import Colorbox from './class/Colorbox';


/**
 *
 * App
 * Main Controller
 *
 * @author acti
 * (vincegobelins, mha, efr, ...)
 */

const app = {
  init: function () {
    if($('.onlyMarmite').length) {
      console.warn('/!\\ If you see this warning message, it means that your are in Marmite Styleguide.\n' +
        'If it\'s not the case, it means that someone has forgot to remove the class .onlyMarmite in dev template\n' +
        'and many js dev code won\'t work properly. :\'(' );
    }

    this.bindUI();

    /*--- initialisation des tailles de viewport ---*/
    store.currentWidth = store.wWidth = helpers.viewport().width;
    store.currentHeight = store.wHeight = helpers.viewport().height;

    store.wScroll = $(window).scrollTop();

    let self = this;


    /*--- responsive-debug ---*/
    let responsiveDebug = new ResponsiveDebug();

    /*--- detectBrowser ---*/
    let detectBrowser = new DetectBrowser();

    /*--- Validation Form ---*/
    let validForm = new ValidForm({
      /*container: '.valid-form',
      fieldContainer: '.form-field',
      validClass: 'as--valid',
      invalidClass: 'as--invalid',
      msgErrorClass: 'form-msg-error',*/
    });

    /*--- cookies ---*/
    store.cookies = new Cookies();

    /*--- Banner messages (cookies consent / warnig / news...) ---*/
    const messagesBanner = new BannerMessages(/*{
      //caping: 3,
    }*/);

    /*--- Skip links ---*/
    let skip = new Collapsible({
      container: '.skip',
      cta: '.skip-cta',
      className: 'as--focused',
      optionHover: true
    });

    if (window.matchMedia('(min-width: 896px)').matches) {
      const body = document.body;
      const scrollUp = 'scroll-up';
      const scrollDown = 'scroll-down';
      let lastScroll = 0;
      window.addEventListener('scroll', () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 1) {
          body.classList.remove(scrollUp);
          return;
        } else if (currentScroll < 1) {
          body.classList.remove(scrollDown);
        }

        if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
          // down
          body.classList.remove(scrollUp);
          body.classList.add(scrollDown);
        } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
          // up
          body.classList.remove(scrollDown);
          body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
      });
    }

    let timer;
    const $firstLevelItem = $('.first-lvl > li.has-submenu');

    $firstLevelItem.mouseenter(function () {
        clearTimeout(timer);
          $(this).addClass('is-hover').siblings('.has-submenu').removeClass('is-hover');
          $('body').addClass('overlay-open');
      }).mouseleave(function () {
        timer = setTimeout(function(){
          $('body').removeClass('overlay-open');
          $firstLevelItem.removeClass('is-hover');
        },300);
      });

    $('.burger-resp').on('click', function(){
      $(this).toggleClass('is-clicked');
      $('.header').toggleClass('is-clicked');
    });

    $('.return').on('click', function(){
      $(this).closest('.has-submenu').removeClass('is-hover');
    });

    $('.slider-one-slide').slick({
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: false,
      dots: true,
      useCSS: false,
      arrows: false,
      adaptiveHeight: false,
    });

    if ($('.slider-multimedia').length) {
      let $slider = $('.slider-multimedia');
      $slider.each(function(){
        $(this).find('.slider-wrap').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          useCSS: true,
          arrow: false,
          adaptiveHeight: true,
          prevArrow: $(this).closest('.slider-multimedia').find('.slick-prev'),
          nextArrow: $(this).closest('.slider-multimedia').find('.slick-next'),
          responsive: [
            {
              breakpoint: 896,
              settings: {
                swipe:true,
                draggable:true,
              }
            }
          ],
        });
      });
    }

    if($('.filters').length){

      if(window.location.href.indexOf('?search=') > -1) {
        $('#body').addClass('has-local-member');
      }

      let buttonText =  $('.fake-default-button label').text();
      const $listRadioBtn = $('.list-radio-buttons');

      if($listRadioBtn.find('input:checked').length) {
        const checkedInput = $('.list-radio-buttons input:checked');
        let selectedText = checkedInput.val();
        $('.fake-default-button label').text(selectedText);
        checkedInput.closest('.group-select').addClass('is-open');
      }

      $('.reset').on('click', function(){
        $listRadioBtn.removeClass('is-clicked').find('input').removeAttr('checked');
        $('.fake-default-button label').text(buttonText);
        $('.select-custom option').removeAttr('selected');
      });

      $('.fake-default-button').on('click', function(){
        $listRadioBtn.toggleClass('is-clicked');
      });

      $('.list-radio-buttons input').on('click', function(){
        let thisValue = $(this).attr('value');
        $('.fake-default-button label').text(thisValue);
        $listRadioBtn.removeClass('is-clicked');
      });

      /* Click outside contact & panel*/
      $('#body').on('click', function(e) {
          if (!$(e.target).closest('.fake-select').length) {
            $listRadioBtn.removeClass('is-clicked');
          }
      });

      $listRadioBtn.on('click', '.btn-show-dep', function() {
          $(this).closest('.group-select').toggleClass('is-open');
      });

      let countCards = 0;
      $('.card-info').each(function(){
        if(countCards < 24){
          $(this).addClass('is-visible');
          countCards++;
        }
      });

      setTimeout(function() {
        if($('.card-info').length < 24){
          $('.see-more').css('display', 'none');
        }
      }, 100);

      $('.see-more').on('click', function(){
        countCards = 0;
        $('.card-info:not(.is-visible)').each(function(){
          if(countCards < 24){
            $(this).addClass('is-visible');
            countCards++;
            setTimeout(function(){
              if($('.card-info').length === $('.is-visible').length){
                $('.see-more').fadeOut();
              }
            }, 100);
          }
        });
      });

    }

    if($('.js-item-inview').length){
      $('.js-item-inview').each(function (i, itemInview) {
        const $itemView = $(itemInview);
        $itemView.one('inview', function (event, isInView) {
          if (isInView) {
            $itemView.addClass('is-inview');
          }
        });
      });
    }

    if ($('.to-top').length) {
      $('.to-top').on('click', '.button-top', function (e) {
        window.scrollTo({top: 0, behavior: 'smooth'});
      });
    }

    $('.search-button').on('click', function(){
      $('.search-block').toggleClass('is-clicked');
    });

    if($('#locationMap').length && window.matchMedia('(min-width: 896px)').matches) {
      const $locationMapWrap = $('.location-map-wrap');
      const $mapTip = $('#mapTip');

      $('.active-region').hover(function() {
        let thisRegion = $(this).data('regionname');
        let thisNumberAdherents = $(this).data('numberadherents');
        $mapTip.addClass('active');
        $mapTip.html('<div><p class="region">' + thisRegion + '</p><p class="numberAdherents">' + thisNumberAdherents + '<span class="adherents"> Adhérents</span></p></div>');
      }, function() {
        $mapTip.removeClass('active');
      });

      $(document).on('mousemove', function(e){
        let x = e.pageX - $locationMapWrap.offset().left - 100;
        let y = e.pageY - $locationMapWrap.offset().top + 50;
        $mapTip.css({
          left: x,
          top: y
        });
      });
    }

    /*--- colorbox ---*/
    /*let colorbox = new Colorbox();*/

    /*--- animation scroll ---*/
    /* Use '.js-scroll-anchor' class to trigger smooth anchor scroll*/
    store.scrollAnchor = new ScrollAnchor();

    /*--- password ---*/
    let showPassword = new ShowPassword();

    /*--- clear input ---*/
    let clearInput = new ClearInput();

    /*--- animated label ---*/
    // let form = new AnimatedLabelField();

    // responsive
    self.onResize();
  },

  checkMobile: function () {
    if (/Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent)) {
      return true;
    }
  },

  onResize: function () {
    let self = this;
  },

  onScroll: function () {
    let self = this;
  },

  bindUI: function () {
    let self = this;

    if (Modernizr.mq('only all')) {
      $(window).on('resize', function () {
        store.wWidth = helpers.viewport().width;
        store.wHeight = helpers.viewport().height;
        if (store.currentHeight !== store.wHeight || store.currentWidth !== store.wWidth) {
          store.currentHeight = store.wHeight;
          store.currentWidth = store.wWidth;
          /*--- timer pour le redimensionnement d'ecran ---*/
          clearTimeout(store.timerResponsive);
          store.timerResponsive = setTimeout(self.onResize.bind(self), 300);
        }
      });
    }

    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
        self.onResize();
      }
    };

    /*--- fonctions au scroll ---*/
    $(window).on('scroll', function () {
      store.wScroll = $(window).scrollTop();

      self.onScroll();
    });

  }
};

app.init();

// global custom functions, they can be called from anywhere within the project (useful for the back-end developers)
let customFunctions = {
  // global custom function example
  // to call it from anywhere : global.customFunction.afterAjaxExample();
  /*afterAjaxExample: function() {
   helpers.resizeImg('.media-block-news');
   }*/
};
// exports the elements that need to be accessed from somewhere else (in the "global" standalone object, cf. gulpfile)
module.exports = customFunctions;
